import React, { useEffect, useState } from 'react'
import { Menu } from './Menu'
import { useNavigate } from 'react-router-dom';



import "./PreLoader3.css";
import { API_URL } from '../utils/config';
import { Loading } from './Loading';
import { Footer } from './Footer';



export const Buscador = () => {



	const [contadorLoading, setContadorLoading] = useState(0);
	const navigate = useNavigate();



	const [campeonatos, setCampeonatos] = useState(null)



	useEffect(() => {
		setContadorLoading(1);
		fetch(API_URL + 'Autocomplete/Campeonatos?get=todos')
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((campeonatosLista) => {
						setCampeonatos(campeonatosLista)
					}); break;
					default: break;
				}
			})
			.catch((error) => { /**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
			.finally(() => { setContadorLoading(-1); });
	}, []);



	const toCampeonato = (campeonato) => {
		localStorage.setItem('campeonatoNombre', campeonato);
		navigate('/torneos/' + campeonato);
	};



	return (
		<>
			<Menu />
			<div className="container mt-5">
				<div className="row row justify-content-md-center">
					<div className="col-md-8">
						<div className="panel-box">
							<div className="titles no-margin">
								<h4>Seleccione el campeonato</h4>
							</div>
							<div className="info-panel">
								<div className="list-group list-campeonato">
									{
										campeonatos !== null
											?
											campeonatos.map(campeonato => {
												return (
													<button
														key={campeonato.Id}
														to={'/campeonato/' + campeonato.NombreUrl}
														className="list-group-item list-group-item-action active mb-1"
														onClick={() => toCampeonato(campeonato.NombreUrl)}>
														{campeonato.Nombre}
													</button>
												);
											})
											:
											<>
												<div className='line mb-3'></div>
												<div className='line mb-3'></div>
												<div className='line mb-3'></div>
												<div className='line mb-3'></div>
											</>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer />
		</>
	)
}