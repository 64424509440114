import React, { useEffect, useContext } from 'react'
import { API_URL, URL_IMAGES, URL_SVG } from '../utils/config';
//import { Meta } from '../utils/MetaUtils';
import { Context } from './Context';


export const Header = ({ idTorneo = null, nombreUrl = null }) => {


	/*
		const [tituloLocalCampeonato, setTituloLocalCampeonato] = useState("")
		const [tituloLocalTorneo, setTituloLocalTorneo] = useState("")
	*/

	const datos = useContext(Context);
	//const [nombreCampeonato, setNombreCampeonato] = useState("");
	//const [nombreTorneo, setNombreTorneo] = useState("");


	useEffect(() => {
		if ((nombreUrl !== null) && (nombreUrl !== datos.datos.nombreUrl)) {
			fetch(API_URL + 'Campeonato/GetData?nombreCampeonato=' + nombreUrl)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((r) => {
							datos.datos.nombreUrl = nombreUrl;
							datos.datos.nombreCampeonato = r.Nombre;
							datos.datos.ImagenLogo = r.ImagenLogo != "" ? r.ImagenLogo : URL_SVG + 'escudo.svg' ;
							datos.setDatos(datos.datos);
						}); break;
						default: break;
					}
				})
				.catch((error) => { })
				.finally(() => { });
		}
		/*
		if ((idTorneo !== null) && (idTorneo !== datos.datos.idTorneo)) {
			fetch(API_URL + 'Torneo/GetData?idTorneo=' + idTorneo)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((r) => {
							datos.datos.nombreCampeonato = r.NombreCampeonato;
							datos.datos.nombreTorneo = r.NombreTorneo;
							datos.datos.idTorneo = idTorneo;
							datos.setDatos(datos.datos);
						}); break;
						default: break;
					}
				})
				.catch((error) => { })
				.finally(() => { });
		}
		*/
	}, [idTorneo, datos, nombreUrl]);



	/*
			useEffect(() => {
				//document.querySelector('meta[property="og:title"]').setAttribute('content', 'Campeonato de Fútbol');
				//document.querySelector('meta[property="og:description"]').setAttribute('content', 'Sitio para administrar campeonatos de fútbol');
				//document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
		
				// ogtitle - Nombre del campeonato
				if (idTorneo !== undefined) {
					fetch(API_URL + 'Torneo/GetData?IdTorneo=' + idTorneo)
						.then((response) => {
							switch (response.status) {
								case 200: response.json().then((r) => {
									setTituloLocalCampeonato(r.NombreCampeonato);
									setTituloLocalTorneo("Torneo: " + r.NombreTorneo);
									if (titulo === "Fixture") {
										Meta({title:r.NombreCampeonato, description:'Fixture del torneo: ' + r.NombreTorneo});
										//document.querySelector('meta[property="og:title"]').setAttribute('content', r.NombreCampeonato);
										//document.querySelector('meta[property="og:description"]').setAttribute('content', 'Fixture del torneo: ' + r.NombreTorneo);
									}
									if (titulo === "Goleadores") {
										Meta({title:'Torneo: ' + r.NombreTorneo, description:"Tabla de Goleadores"});
										//document.querySelector('meta[property="og:title"]').setAttribute('content', 'Torneo: ' + r.NombreTorneo);
										//document.querySelector('meta[property="og:description"]').setAttribute('content', "Tabla de Goleadores");
									}
									if (titulo === "Jugador") {
										Meta({description:'Datos del jugador'});
										//document.querySelector('meta[property="og:description"]').setAttribute('content', 'Datos del jugador');
									}
									if (titulo === "Jugadores") {
										Meta({description:'Listado de Jugadores'});
										//document.querySelector('meta[property="og:description"]').setAttribute('content', 'Listado de Jugadores');
									}
									if (titulo === "Tabla de posiciones") {
										Meta({title:'Torneo: ' + r.NombreTorneo, description:'Tabla de Posiciones'});
										//document.querySelector('meta[property="og:title"]').setAttribute('content', 'Torneo: ' + r.NombreTorneo);
										//document.querySelector('meta[property="og:description"]').setAttribute('content', 'Tabla de Posiciones');
									}
		
									// setEquipo(r.equipo)
								}); break;
								default: break;
							}
						})
						.catch((error) => { })
						.finally(() => { });
				}
			}, [idTorneo, titulo]);
	*/

	/*
	<meta property="og:site_name" content="San Roque 2014 Pollos">
	<meta property="og:title" content="San Roque 2014 Pollos" />
	<meta property="og:description" content="Programa de fiestas" />
	<meta property="og:image" itemprop="image" content="http://pollosweb.wesped.es/programa_pollos/play.png">
	<meta property="og:type" content="website" />
	<meta property="og:updated_time" content="1440432930" />
	*/

	console.log(datos);



	return (
		<header className="titulo">
			<div className="section-title d-flex">
				<div className="container d-flex h-100">
					
					<div className="row align-items-end">
						{
							datos.datos.nombreCampeonato !== undefined &&
							<>
								<h6 className='fw-bold text-light'>Campeonato</h6>
								<h3 className='fw-bold text-light'>{datos.datos.nombreCampeonato || ""}</h3>
							</>
						}
						{
							datos.datos.nombreTorneo !== undefined &&
							<>
								<h6 className='fw-bold text-light'>Torneo</h6>
								<h4 className='fw-bold text-light'>{datos.datos.nombreTorneo || ""}</h4>
							</>
						}
					</div>
					
				</div>
				<img className='img-fluid' src={datos.datos.ImagenLogo} alt={datos.datos.nombreCampeonato}/>
			</div>
		</header>
	)
}
